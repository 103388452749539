import React from 'react';
import styles from './info.module.css';

import newIcon from '../../img/icons/new.png';
import usedIcon from '../../img/icons/used.png';
import warrrantyIcon from '../../img/icons/warranty.png';

export const Info = () => {
  return (
    <div className={styles.infoContainer}>
      <h1>Információk</h1>
      <h2>Mit érdemes tudni a számítógépekről?</h2>
      <ul>
        <li>Használt és új alkatrészekből készülnek el a gépek, melyeket én tesztelek.</li>
        <li>Mindenhez oda van téve a piktogram, ami jelzi, hogy használt vagy új alkatrész, illetve a garanciát is jelzem.
          <img src={newIcon} style={{ width: '22px' }} alt="new" title="Új" />
          <img src={usedIcon} style={{ width: '22px' }} alt="used" title="Használt" />
          <img src={warrrantyIcon} style={{ width: '22px' }} alt="warranty" title="Garancia" />
        </li>
        <li>Nem webshop vagyok, ez az oldal csak azért készült, hogy az elkészült számítógépeket egy oldalon meg tudjam mutatni.</li>
        <li>Nem tartogatom napokig, nem várok, ígéretből sok van a világon. Az viszi, aki a leggyorsabb.</li>
        <li>Semmilyen adatot nem gyűjtök az oldalon keresztül.</li>
        <li>A kapcsolatfelvétel HA-n keresztül lehetséges (egyelőre).</li>
      </ul>
      <h2>Mit érdemes rólam tudni?</h2>
      <ul>
        <li>Egy huszonéves Mérnökinformatikus srác vagyok, aki szereti a számítógépeket.</li>
        <li>Minden számítógépet én rakok össze és tesztelek, minden egyesről én készítem a benchmark videót, amiben bemutatom, hogy néhány játéknál hogyan teljesít a számítógép.</li>
        <li>Személyesen Debrecenben van lehetőség megnézni, előre egyeztetett időpontban.</li>
      </ul>
    </div>
  );
};
