import React, { useRef } from 'react';
import Products from './products';
import Welcome from './welcome';
import AvailableProducts from './availableproducts';

export const Home = () => {
  const productsRef = useRef<HTMLDivElement>(null);

  const scrollToProducts = () => {
    if (productsRef.current) {
      productsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Welcome onSeeProductsClick={scrollToProducts} />
      <AvailableProducts />
      <Products ref={productsRef} />
    </div>
  );
};
