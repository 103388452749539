import React, { useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import toxylogo from '../img/toxylogo.png';
import './navbar.css';

export const Navbar = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <nav>
            <Link to="/" className='title'><img src={toxylogo} alt="Toxy" /></Link>
            <div className='menu' onClick={() => setMenuOpen(!menuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={menuOpen ? 'open' : ''}>
                <li>
                    <NavLink to="/">Kezdőlap</NavLink>
                </li>
                <li>
                    <NavLink to="/info">Információk</NavLink>
                </li>
            </ul>
        </nav>
    )
}
