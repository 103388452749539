import React, { forwardRef, useState } from 'react';
import styles from './products.module.css'; // Import CSS Modules
import ImageModal from '../imagemodal/imagemodal';

import cpuImg from '../../img/icons/cpu.png';
import gpuImg from '../../img/icons/vga.png';
import ramImg from '../../img/icons/ram.png';
import motherboardImg from '../../img/icons/motherboard.png';
import coolerImg from '../../img/icons/cpucooler.png';
import m2ssdImg from '../../img/icons/ssd.png';
import psuImg from '../../img/icons/psu.png';
import fanImg from '../../img/icons/fan.png';
import caseImg from '../../img/icons/case.png';

import txypc1default from '../../img/txyconfig1/txypc1default.jpg';
import txypc2default from '../../img/txyconfig2/txypc2default.jpg';

import newIcon from '../../img/icons/new.png';
import usedIcon from '../../img/icons/used.png';
import warrrantyIcon from '../../img/icons/warranty.png';

import haIcon from '../../img/icons/ha.png';
import benchmarkIcon from '../../img/icons/benchmark.png';

import building from '../../img/icons/building.jpg';

import imgMore1 from '../../img/txyconfig2/moreimg/1.jpg';
import imgMore2 from '../../img/txyconfig2/moreimg/2.jpg';
import imgMore3 from '../../img/txyconfig2/moreimg/3.jpg';
import imgMore4 from '../../img/txyconfig2/moreimg/4.jpg';
import imgMore5 from '../../img/txyconfig2/moreimg/5.jpg';
import imgMore6 from '../../img/txyconfig2/moreimg/6.jpg';
import imgMore7 from '../../img/txyconfig2/moreimg/7.jpg';
import imgMore8 from '../../img/txyconfig2/moreimg/8.jpg';
import imgMore9 from '../../img/txyconfig2/moreimg/9.jpg';

const Products = forwardRef<HTMLDivElement, any>((props, ref) => {
  // Manuálisan megadott termékek listája
  const [productList, setProductList] = useState([
   
    {
      id: 3,
      name: 'WHITE RGB GAMER PC',
      cpu: 'Intel Core i5-12400F',
      cpuNew: false,
      cpuUsed: true,
      cpuWarranty: true,
      gpu: 'MSI Geforce RTX 3060 Gaming Trio X',
      gpuNew: false,
      gpuUsed: true,
      gpuWarranty: false,
      ram: 'G.SKILL Trident Z5 1x16GB DDR5 5600MHz',
      ramNew: false,
      ramUsed: true,
      ramWarranty: false,
      motherboard: 'ASRock B760M-HDV/M.2',
      motherboardNew: true,
      motherboardUsed: false,
      motherboardWarranty: true,
      cooler: 'Darkflash Darkair RGB Fehér',
      coolerNew: true,
      coolerUsed: false,
      coolerWarranty: false,
      ssd: 'Western Digital SN740 512GB NVME',
      ssdNew: true,
      ssdUsed: false,
      ssdWarranty: true,
      psu: 'Corsair CX650M 650Watt 80Plus',
      psuNew: false,
      psuUsed: true,
      psuWarranty: false,
      casefan: '4x beépített',
      casefanNew: true,
      casefanUsed: false,
      casefanWarranty: true,
      case: 'Darkflash DK351 Fehér',
      caseNew: true,
      caseUsed: false,
      caseWarranty: true,
      price: '',
      image: txypc2default,
      imageMore: [imgMore1, imgMore2, imgMore3, imgMore4, imgMore5, imgMore6, imgMore7, imgMore8, imgMore9],
      hardveraproLink: 'https://hardverapro.hu/apro/hofeher_rgb_gamer_pc_rtx_3060_i5_12400f_ddr5_16gb/hsz_1-200.html',
      benchmarkLink: 'https://www.youtube.com/watch?v=IqlMU5ncKk4',
    },
    {
        id: 2,
        name: 'Készül...',
        cpu: 'Intel Core i5-12400F',
        cpuNew: false,
        cpuUsed: true,
        cpuWarranty: true,
        gpu: 'Palit GeForce RTX 2060 Super',
        gpuNew: false,
        gpuUsed: true,
        gpuWarranty: false,
        ram: '2x8GB Crucial Ballistix DDR4 3200 cl16',
        ramNew: false,
        ramUsed: true,
        ramWarranty: false,
        motherboard: '...',
        motherboardNew: false,
        motherboardUsed: false,
        motherboardWarranty: false,
        cooler: 'Darkflash Darkair RGB Fekete',
        coolerNew: true,
        coolerUsed: false,
        coolerWarranty: false,
        ssd: 'Western Digital SN740 512GB NVME',
        ssdNew: true,
        ssdUsed: false,
        ssdWarranty: true,
        psu: 'Cooler Master G450M 80+ Bronze',
        psuNew: false,
        psuUsed: true,
        psuWarranty: false,
        casefan: '4x beépített',
        casefanNew: true,
        casefanUsed: false,
        casefanWarranty: true,
        case: 'Darkflash DK351 Fekete',
        caseNew: true,
        caseUsed: false,
        caseWarranty: true,
        price: '',
        image: building,
        hardveraproLink: '',
        benchmarkLink: '',
        sold: true,
      },
    {
      id: 1,
      name: 'KEZDŐ GAMER PC ',
      cpu: 'Intel Core i5-8400',
      cpuNew: false,
      cpuUsed: true,
      cpuWarranty: false,
      gpu: 'Asus Dual GTX 1060 OC 3gb',
      gpuNew: false,
      gpuUsed: true,
      gpuWarranty: false,
      ram: '2xHyperX 8GB DDR4 2400MHz CL15 Fury',
      ramNew: false,
      ramUsed: true,
      ramWarranty: false,
      motherboard: 'ASUS TUF Z390-PLUS GAMING',
      motherboardNew: false,
      motherboardUsed: true,
      motherboardWarranty: false,
      cooler: 'Cooler Master Hyper 212 Black Edition',
      coolerNew: false,
      coolerUsed: true,
      coolerWarranty: false,
      ssd: '128GB m.2 SSD + 500GB HDD',
      ssdNew: false,
      ssdUsed: true,
      ssdWarranty: false,
      psu: 'Gigabyte GP-PB 500W',
      psuNew: false,
      psuUsed: true,
      psuWarranty: false,
      casefan: '4x beépített',
      casefanNew: true,
      casefanUsed: false,
      casefanWarranty: false,
      case: 'ZALMAN N5 MF',
      caseNew: true,
      caseUsed: false,
      caseWarranty: false,
      price: '',
      image: txypc1default,
      sold: true,

      hardveraproLink: 'https://hardverapro.hu/apro/hofeh%C3%A9r-rgb-gamer-pc',
      benchmarkLink: 'https://www.userbenchmark.com/UserRun/47783757',
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState<string[]>([]);

  const openImageModal = (images: string[]) => {
    setCurrentImages(images);
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
    setCurrentImages([]);
  };
  return (
    <>
      <div className={`${styles.itemsContainer}`} id="products" ref={ref}>
        <div className={styles.productGrid}>
          {productList.map((product) => (
            <div className={`${styles.productCard} ${product.sold ? styles.sold : ''}`} key={product.id}>
              <div
                className={styles.productCardImage}
                onClick={() => product.imageMore && product.imageMore.length > 0 ? openImageModal(product.imageMore) : null}
              >
                <img src={product.image} alt={product.name} className={styles.productImage} draggable="false" />
              </div>
              <div className={styles.productCardInfo}>
                <h2 className={styles.productCardTitle}>{product.name}</h2>
                <div className={styles.details}>
                  <div className={styles.size}>
                    <h4>Specifikáció:</h4>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={cpuImg} className={styles.inlineIcon} alt="CPU" title="CPU" draggable="false" />
                        <p>{product.cpu}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.cpuUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.cpuNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.cpuWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={gpuImg} className={styles.inlineIcon} alt="GPU" title="GPU" draggable="false" />
                        <p>{product.gpu}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.gpuUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.gpuNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.gpuWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={ramImg} className={styles.inlineIcon} alt="RAM" title="RAM" draggable="false" />
                        <p>{product.ram}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.ramUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.ramNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.ramWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={motherboardImg} className={styles.inlineIcon} alt="Motherboard" title="Alaplap" draggable="false" />
                        <p>{product.motherboard}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.motherboardUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.motherboardNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.motherboardWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={coolerImg} className={styles.inlineIcon} alt="Cooler" title="Hűtő" draggable="false" />
                        <p>{product.cooler}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.coolerUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.coolerNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.coolerWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={m2ssdImg} className={styles.inlineIcon} alt="SSD" title="SSD" draggable="false" />
                        <p>{product.ssd}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.ssdUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.ssdNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.ssdWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={psuImg} className={styles.inlineIcon} alt="PSU" title="Tápegység" draggable="false" />
                        <p>{product.psu}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.psuUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.psuNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.psuWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={fanImg} className={styles.inlineIcon} alt="Case Fan" title="Házventillátor" draggable="false" />
                        <p>{product.casefan}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.casefanUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.casefanNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.casefanWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                    <div className={styles.inlineItem}>
                      <div className={styles.iconAndText}>
                        <img src={caseImg} className={styles.inlineIcon} alt="Case" title="Ház" draggable="false" />
                        <p>{product.case}</p>
                      </div>
                      <div className={styles.smallIcons}>
                        {product.caseUsed && <img src={usedIcon} className={styles.inlineIconSmall} alt="used" title="Használt" draggable="false" />}
                        {product.caseNew && <img src={newIcon} className={styles.inlineIconSmall} alt="new" title="Új" draggable="false" />}
                        {product.caseWarranty && <img src={warrrantyIcon} className={styles.inlineIconSmall} alt="warranty" title="Garancia" draggable="false" />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.productCardPriceRow}>
                  <p className={styles.productCardPrice}>{product.price}</p>
                </div>
                <div className={styles.buttonContainer}>
                  <button className={styles.btnBenchmark}
                    onClick={() => window.open(product.benchmarkLink, '_blank')}
                    disabled={product.sold}>
                    <img src={benchmarkIcon} className={styles.benchmarkIcon} alt="Benchmark" title="Benchmark" draggable="false" />
                  </button>
                  <button className={styles.btnHardverapro}
                    onClick={() => window.open(product.hardveraproLink, '_blank')}
                    disabled={product.sold}>
                    <img src={haIcon} className={styles.haIcon} alt="Hardverapro" title="Hardverapro" draggable="false" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ImageModal images={currentImages} isOpen={isModalOpen} onClose={closeImageModal} />

    </>
  );
});

export default Products;