import React, { useState } from 'react';
import styles from './availableproducts.module.css';

import txytext from '../../img/txytext.png';

import cpuImg from '../../img/icons/cpu.png';
import gpuImg from '../../img/icons/vga.png';
import ramImg from '../../img/icons/ram.png';
import motherboardImg from '../../img/icons/motherboard.png';
import coolerImg from '../../img/icons/cpucooler.png';
import m2ssdImg from '../../img/icons/ssd.png';
import psuImg from '../../img/icons/psu.png';
import fanImg from '../../img/icons/fan.png';
import caseImg from '../../img/icons/case.png';

import txypc2transparent from '../../img/txyconfig2/txypc2transparent.png';

const AvailableProducts = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState<string>('');

    const productList = [
        {
            name: 'WHITE RGB GAMER PC',
            cpu: 'Intel Core i5-12400F',
            gpu: 'MSI Geforce RTX 3060 Gaming Trio X',
            ram: 'G.SKILL Trident Z5 1x16GB DDR5 5600MHz',
            motherboard: 'ASRock B760M-HDV/M.2',
            cooler: 'Darkflash Darkair RGB Fehér',
            ssd: 'Western Digital SN740 512GB NVME',
            psu: 'Corsair CX650M 650Watt 80Plus',
            casefan: '4x beépített',
            case: 'Darkflash DK351 Fehér',
            bgcolor: '#62cff4',
            img: txypc2transparent,
        },
    ];

    const handlePrev = () => {
        setFade(styles.fadeOut);
        setTimeout(() => {
            setCurrentIndex(prevIndex => (prevIndex === 0 ? productList.length - 1 : prevIndex - 1));
            setFade(styles.fadeIn);
        }, 500);
    };

    const handleNext = () => {
        setFade(styles.fadeOut);
        setTimeout(() => {
            setCurrentIndex(prevIndex => (prevIndex === productList.length - 1 ? 0 : prevIndex + 1));
            setFade(styles.fadeIn);
        }, 500);
    };

    const currentProduct = productList[currentIndex];

    return (
        <div className={`${styles.homeContainer}`} id="home">
            {productList.length > 0 ? (
                <div className={`${styles.card} ${fade}`}>
                    <div className={styles.left} style={{ backgroundColor: currentProduct.bgcolor }}>
                        <div className={styles.slider} onClick={handlePrev}>
                            <i className="material-icons">arrow_back</i>
                        </div>
                        <img src={txytext} className={styles.wordmark} alt="Toxy" />
                    </div>
                    <div className={styles.right}>
                        <img src={currentProduct.img} className={styles.helmet} alt="PC" />
                        <div className={styles.productInfo}>
                            <h3>Elérhető számítógépek</h3>
                            <h1>{currentProduct.name}</h1>
                            <div className={styles.details}>
                                <h4>Specifikáció</h4>
                                {[
                                    { img: cpuImg, text: currentProduct.cpu },
                                    { img: gpuImg, text: currentProduct.gpu },
                                    { img: ramImg, text: currentProduct.ram },
                                    { img: motherboardImg, text: currentProduct.motherboard },
                                    { img: coolerImg, text: currentProduct.cooler },
                                    { img: m2ssdImg, text: currentProduct.ssd },
                                    { img: psuImg, text: currentProduct.psu },
                                    { img: fanImg, text: currentProduct.casefan },
                                    { img: caseImg, text: currentProduct.case },
                                ].map(({ img, text }, index) => (
                                    <div key={index} className={styles.inlineItem}>
                                        <img src={img} alt={text} className={styles.inlineIcon} />
                                        <p>{text}</p>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.slider} onClick={handleNext}>
                                <i className="material-icons">arrow_forward</i>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>Nincs megjeleníthető termék.</div>
            )}
        </div>
    );
};

export default AvailableProducts;
