import React from 'react';
import styles from './imagemodal.module.css';

interface ImageModalProps {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ images, isOpen, onClose }) => {
  // Ha a modál nincs nyitva, ne jelenítse meg
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose} aria-label="Close modal">
          &times;
        </button>
        <div className={styles.imageGallery}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Product Image ${index + 1}`}
              className={styles.modalImage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
