import React from 'react';
import styles from './welcome.module.css';

interface WelcomeProps {
  onSeeProductsClick: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ onSeeProductsClick }) => {
  return (
    <div className={styles.welcomeContainer} id="welcome">
      <h1 className={styles.welcome_h1}>Üdvözöllek</h1>
      <h3 className={styles.welcome_h3}>Nézz szét az elkészült számítógépek között.</h3>  
      <div className={styles.buttons}>
        <button className={styles.downloadBtn} onClick={onSeeProductsClick}>Megnézem</button>
      </div>
      <div className={styles.welcome}></div>
    </div>
  );
};

export default Welcome;
